import enums from '../constants/enums';

function getBrands() {
  return {
    [enums.engine.CALL_API]: {
      endpoint: 'https://api.hayfons.com/front/brands',
      method: 'GET',
    },
  };
}

function getSuggestedPrice({ model, capacity, condition }) {
  return {
    [enums.engine.CALL_API]: {
      endpoint: `https://api.hayfons.com/front/suggestedPrice/${model.id}/${condition}/${capacity.value}`,
      method: 'GET',
    },
  };
}

export default {
  getBrands,
  getSuggestedPrice,
};
