import React from "react";
import numeral from "numeral";

import "./Resume.scss";

const Resume = ({ selection, showButton, isLoading, price }) => {
  const { brand, model, capacity, condition } = selection;

  const getTitle = () => {
    switch (condition) {
      case "GOOD":
        return "Regular";
      case "EXCELLENT":
        return "Excelente";
      case "REGULAR":
      default:
        return "Deficiente";
    }
  };

  return (
    <div className="resume-info">
      <div className="resume resume--aside">
        <div className="resume-body">
          <h3 className="resume-body-title">Resumen de tu teléfono</h3>
          <ul className="resume-list resume-list-process">
            <li className="resume-list-item" id="resume-brand">
              <small>
                <strong>1. Marca</strong>
              </small>
              {brand && (
                <p className="resume-list-item-data fade">{brand.name}</p>
              )}
            </li>
            <li className="resume-list-item" id="resume-brand">
              <small>
                <strong>2. Modelo</strong>
              </small>
              {model && (
                <p className="resume-list-item-data fade">{model.name}</p>
              )}
            </li>
            <li className="resume-list-item" id="resume-brand">
              <small>
                <strong>3. Capacidad</strong>
              </small>
              {capacity && (
                <p className="resume-list-item-data fade">{capacity.display}</p>
              )}
            </li>
            <li className="resume-list-item" id="resume-brand">
              <small>
                <strong>4. Estado</strong>
              </small>
              {condition && (
                <p className="resume-list-item-data fade">{getTitle()}</p>
              )}
            </li>
          </ul>
          {capacity && condition && !isLoading && price &&  (
            <div className="resume-total block fade">
              <small>Tenemos una oferta para ti:</small>
              <p className="resume--price-final">
                MXN {numeral(price).format("$0,0[.]00")}
              </p>
              {showButton && (
                <button type="submit" className="btn-theme">
                  Lo quiero
                </button>
              )}
              <small className="enfatize-text resume--price-final-note">
                <strong>
                  ¡Apresúrate antes que <br /> cambie la oferta!
                </strong>
              </small>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Resume.defaultProps = {
  showButton: false,
};

export default Resume;
