import React, { useEffect, useState } from "react";
import HtmlMetaData from "../../components/htmlMetaData";
import step4Success from '../../img/step4-success.svg';

function SellConfirmation(props) {
  const { match: { params }, history: { push } } = props;
  const [ info, setInfo ] = useState({});

  useEffect(() => {
    const { confirmationId } = params;
    const data = localStorage.getItem(confirmationId);
    if (data) {
      setInfo(JSON.parse(data));
    } else {
      push('/');
    }
  }, [params, push]);

  return (
    <div className="sell-user-info">
      <HtmlMetaData title="HayFons | Confirmación" />
      <div className="container-sm">
        <img src={step4Success} alt="" className="img-responsive img-success" />
        <h1 className="line-title line-title--center">
          <span className="line-title-hr" />
          <span className="line-title-text">¡Gracias, haz completado el proceso!</span>
        </h1>
        <h3>
          Enviamos un correo a
          {' '}
          <strong>{info && info.seller ? info.seller.email : ''}</strong> con todo lo necesario para cobrar tu oferta. Si tienes dudas llámanos al
          {' '}
          <strong>998 390 1688</strong>
        </h3>
        <h4>
          <span className="icon-flag" />
          {' '}
          <strong>¿Qué sigue?</strong>
        </h4>
        <p>En breve nos podremos en contacto contigo para concretar una cita para poder entregarte tu pago</p>
        <br />
        <h4>
          <span className="icon-android-contact" />
          {' '}
          <strong>¿Quién me contactará?</strong>
        </h4>
        <p>
          Te contactará alguien de nuestro equipo para informarte todo lo necesario para entregarte tu equipo.
        </p>
        <br />
        <h4>
          <span className="icon-ios-location" />
          {' '}
          <strong>
            Lugar de la cita
          </strong>
        </h4>
        <p>
          Las citas de entrega son en lugares públicos para tu seguridad, puede ser en Plaza las Americas, La gran plaza o Plaza Outlet
        </p>
      </div>
    </div>
  );
}

export default SellConfirmation;
