import React from 'react';

const ListInput = (props) => {
  const {
    options,
    RenderComponent,
    field: { value },
    onClick,
    onTouch,
    display,
  } = props;


  const onItemClick = option => () => {
    onClick(option);
  };

  const renderOption = (option, i) => {
    if (RenderComponent) {
      const props = {
        option,
        selection: value,
        onClick: onItemClick(option),
        onBlur: onTouch,
        display,
      };

      return <RenderComponent key={i} {...props} />;
    }

    return (
      <button
        key={i}
        onBlur={onTouch}
        onClick={onClick}
      >
        <div>
          <h3>
            {option.name}
          </h3>
        </div>
      </button>
    );
  };

  return (
    <React.Fragment>
      {options.map(renderOption)}
    </React.Fragment>
  );
};

export default ListInput;
