export default {
  engine: {
    CALL_API: 'CALL_API',
  },
  conditions: {
    EXCELLENT: 'EXCELLENT',
    GOOD: 'GOOD',
    REGULAR: 'REGULAR',
  },
};
