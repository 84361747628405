import React, { useState } from 'react';
import { string, node } from 'prop-types';
import classnames from 'classnames';

import './Question.scss';

const Question = (props) => {
  const { question, answer } = props;
  const [isOpen, setIsOpen] = useState(false);
  const togglePanel = () => setIsOpen(!isOpen);
  const contentClasses = classnames({
    'card-content': true,
    isOpen,
  });

  return (
    <div className="card faq-question">
      <h3 className="card-header" onClick={togglePanel}>
        <span className="card-header-title">
          {question}
        </span>
        <span className="card-header-icon">
          <span className="icon-arrow-down-b" />
        </span>
      </h3>
      <div className={contentClasses}>
        {answer}
      </div>
    </div>
  );
};

Question.propTypes = {
  question: string,
  answer: node,
};

export default Question;
