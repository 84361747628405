import { createTypes, asyncAction } from 'redux-action-types';
import quoteApi from '../../engine/quoteApi';

export const types = createTypes('app/quoteActions',
  asyncAction('QUOTE'),
);

const setQuote = value => {
  return {
    type: types.QUOTE_SUCCESS,
    value,
  };
};

const getOffer = request => (dispatch) => {
  return dispatch(quoteApi.getOffer(request));
};

export default {
  setQuote,
  getOffer,
};
