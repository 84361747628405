import React from 'react';
import { Link } from "react-router-dom";
import logo from '../../img/logo.svg';
/* eslint-disable jsx-a11y/anchor-is-valid */

import './Footer.scss';

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="box-footer">
          <img src={logo} alt="Hayfons" />
          <p>
            Somos una empresa mexicana fundada en 2016 con el objetivo de ofrecer una alternativa confiable a la compra y venta de celulares nuevos y de medio uso, siendo así una empresa amigable con el medio ambiente fomentando el reciclaje de celulares y la mejor empresa del mercado.
          </p>
        </div>
        <div className="box-footer">
          <h4>Servicios</h4>
          <ul>
            <li>
              <a href="https://compra.hayfons.com/">
                Comprar teléfono usado
              </a>
            </li>
            <li>
              <Link to="/">
                Vender teléfono usado
              </Link>
            </li>
            <li>
              <Link to="/como-funciona">
                Cómo funciona
              </Link>
            </li>
            <li>
              <Link to="/preguntas-frecuentes">
                Preguntas frecuentes
              </Link>
            </li>
            <li>
              <Link to="/contacto">
                Contacto
              </Link>
            </li>
          </ul>
        </div>
        <div className="box-footer">
          <h4>Ayuda</h4>
          <ul>
            <li>
              <a href="https://wa.me/9983901688" target="_blank" rel="noopener noreferrer">
                <span className="icon-social-whatsapp" /> 998 390 1688
              </a>
            </li>
            <li>
              <a href="mailto:info@hayfons.com">
                <span className="icon-android-mail" /> info@hayfons.com
              </a>
            </li>
            <li>
              <a href="https://facebook.com/Hayfons-452999878571248/" target="_blank" rel="noopener noreferrer">
                <span className="icon-social-facebook" /> Facebook
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/hayfons/" target="_blank" rel="noopener noreferrer">
                <span className="icon-social-instagram" /> Instagram
              </a>
            </li>
            <li>
              <a href="https://twitter.com/hayfons" target="_blank" rel="noopener noreferrer">
                <span className="icon-social-twitter" /> Twitter
              </a>
            </li>
          </ul>
        </div>
        <div className="box-footer-row">
          <div className="footer-details">
            <p>HayFons © {new Date().getFullYear()}</p>
            <a href="#">
              Términos y condiciones
            </a>
            <Link to="/preguntas-frecuentes">
              Preguntas frecuentes
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
