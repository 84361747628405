import React from 'react';
import { Formik, Form, Field } from 'formik';
import classnames from 'classnames';
import HtmlMetaData from '../../components/htmlMetaData';
import { validateText, validateEmail } from '../../utils/validate';

function ContactPage() {
  return (
    <div className="contact-page">
      <HtmlMetaData title="HayFons | Contacto" />
      <div className="container-sm">
        <h1 className="line-title line-title--center">
          <span className="line-title-hr" />
          <span className="line-title-text">Ponte en contacto con nosotros</span>
        </h1>
        <h3>
          ¿Tienes dudas? Déjanos ayudarte, te ofrecemos
          {' '}
          <strong>dos formas de contactarnos:</strong>
        </h3>
        <section className="contact">
          <h3 className="line-title">
            <span className="line-title-hr" />
            <span className="line-title-text">
              <span className="enfatize-text">WHATSAPP</span>
            </span>
          </h3>
          <div className="card">
            <div className="card-header">
              <h3 className="card-header-title">
                <span className="icon-social-whatsapp" />
                {' '}
                <strong> +52 998 390 1688</strong>
              </h3>
            </div>
            <div className="card-body">
              <p>Lunes a Viernes de 9am a 6pm y Sábados de 9am a 2pm</p>
            </div>
          </div>
        </section>
        <section className="contact">
          <h3 className="line-title">
            <span className="line-title-hr" />
            <span className="line-title-text">
              <span className="enfatize-text">CORREO</span>
            </span>
          </h3>
          <div className="card">
            <div className="card-header">
              <h3 className="card-header-title">
                <span className="icon-android-mail" />
                {' '}
                <strong> Déjanos tus dudas y te contestaremos lo más pronto posible</strong>
              </h3>
            </div>
            <div className="card-body">
              <Formik
                initialValues={{
                  name: undefined,
                  lastName: undefined,
                  email: undefined,
                  telephone: undefined,
                }}
                onSubmit={values => {
                  console.log(values);
                }}
              >
                {({ errors, touched }) => (
                  <Form className="form-contact-simple">
                    <div className={classnames({
                      'form-group': true,
                      error: errors.name && touched.name,
                    })}>
                      <label htmlFor="name">Nombre</label>
                      <Field name="name" className="form-control" validate={validateText} />
                    </div>
                    <div className={classnames({
                      'form-group': true,
                      error: errors.lastName && touched.lastName,
                    })}>
                      <label htmlFor="lastName">Apellidos</label>
                      <Field name="lastName" className="form-control" validate={validateText} />
                    </div>
                    <div className={classnames({
                      'form-group': true,
                      error: errors.email && touched.email,
                    })}>
                      <label htmlFor="email">Email</label>
                      <Field name="email" type="email" className="form-control" validate={validateEmail} />
                    </div>
                    <div className={classnames({
                      'form-group': true,
                      error: errors.telephone && touched.telephone,
                    })}>
                      <label htmlFor="telephone">Teléfono</label>
                      <Field name="telephone" type="telephone" className="form-control" validate={validateText} />
                    </div>
                    <button type="submit" className="btn-theme">
                      Enviar
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default ContactPage;
