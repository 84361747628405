import React from 'react';
import { BrowserRouter as Router,  Route/* , Redirect */ } from "react-router-dom";
import { createBrowserHistory as createHistory } from 'history';
import { Provider } from 'react-redux';
import Header from './components/header';
import Footer from './components/footer';
import ToSellPage from './pages/toSellPage';
import SellCellphonePage from './pages/sellCellphonePage';
import SellUserInfoPage from './pages/sellUserInfoPage';
import SellConfirmation from './pages/sellConfirmation';
import FaqPage from './pages/faqPage';
import HowItWorksPage from './pages/howItWorksPage';
import ContactPage from './pages/contactPage';
import configureStore from './store/configureStore';

import './App.scss';

const store = configureStore(createHistory());

function App() {
  return (
    <Provider store={store}>
      <Router>
        <div className="hayfons-page">
          <Header />
          <Route exact path="/" component={ToSellPage} />
          <Route exact path="/vender/celular" component={SellCellphonePage} />
          <Route exact path="/vender/informacion-usario/:quoteId" component={SellUserInfoPage} />
          <Route exact path="/vender/confirmacion/:confirmationId" component={SellConfirmation} />
          <Route exact path="/preguntas-frecuentes" component={FaqPage} />
          <Route exact path="/como-funciona" component={HowItWorksPage} />
          <Route exact path="/contacto" component={ContactPage} />
          {/* <Route path="*">
            <Redirect to="/" />
          </Route> */}
          <Footer />
        </div>
      </Router>
    </Provider>
  );
}

export default App;
