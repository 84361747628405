import React from 'react';
import { Link } from "react-router-dom";
import HtmlMetaData from '../../components/htmlMetaData';
import jumbotronBuy from '../../img/jumbotron-buy.svg';
import step1 from '../../img/step1-buy.svg';
import step2 from '../../img/step2-buy.svg';
import step3 from '../../img/step3-buy.svg';
/* eslint-disable jsx-a11y/anchor-is-valid */
import './ToSellPage.scss';

function ToSellPage() {
  return (
    <div className="to-sell-page">
      <HtmlMetaData title="HayFons | Compra y Venta de celulares usados" />
      {/* Jumbotron */}
      <div className="jumbotron">
        <div className="container">
          <div className="jumbotron-container">
            <div className="jumbotron-column">
              <h1 className="jumbotron-title">
                Vende tu teléfono en minutos
              </h1>
              <p className="jumbotron-p">
                Ahorra tiempo y esfuerzo, nosotros te ofrecemos el precio justo por tu teléfono.
              </p>
              <ul className="list-inline list-inline--iconsBrand">
                <li className="list-inline--item">
                  <p>
                    Aceptamos:
                  </p>
                </li>
                <li className="list-inline--item">
                  <span className="icon-social-apple" />
                  <p>
                    iPhone
                  </p>
                </li>
                <li className="list-inline--item">
                  <span className="icon-social-android" />
                  <p>
                    Samsung
                  </p>
                </li>
              </ul>
              <Link to="vender/celular" className="btn-theme">
                ¡Vender ahora!
              </Link>
            </div>
            <div className="jumbotron-column">
              <img
                src={jumbotronBuy}
                className="img-responsive img-jumbotron"
                alt="Compra y venta de celulares usados"
              />
            </div>
          </div>
        </div>
      </div>
      {/* Jumbotron End */}
      {/* Content */}
      <div className="container">
        <section className="steps">
          <h3 className="line-title">
            <span className="line-title-hr" />
            <span className="line-title-text">
              ¿Cómo funciona?
            </span>
          </h3>
          <div className="grid grid-3-col">
            <div className="grid-item">
              <div className="card card--step">
                <div className="card-header">
                  <h3 className="card-header-title">
                    1.- Cuéntanos las características de tu teléfono
                  </h3>
                </div>
                <div className="card-body">
                  <div className="card-body-column">
                    <img src={step1} alt="Primer Paso" className="img-responsive" />
                  </div>
                </div>
              </div>
            </div>
            <div className="grid-item">
              <div className="card card--step">
                <div className="card-header">
                  <h3 className="card-header-title">
                    2.- Déjanos tus datos de contacto
                  </h3>
                </div>
                <div className="card-body">
                  <div className="card-body-column">
                    <img src={step2} alt="Segundo Paso" className="img-responsive" />
                  </div>
                </div>
              </div>
            </div>
            <div className="grid-item">
              <div className="card card--step">
                <div className="card-header">
                  <h3 className="card-header-title">
                    3.- ¡Recibe tu pago!
                  </h3>
                </div>
                <div className="card-body">
                  <div className="card-body-column">
                    <img src={step3} alt="Tercer paso" className="img-responsive" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="comments">
          <h3 className="line-title">
            <span className="line-title-hr" />
            <span className="line-title-text">
              Sé parte de vendedores satisfechos
            </span>
          </h3>
          <div className="aside-main">
            <div className="aside">
              <div className="resume resume--comments">
                <div className="resume-body">
                  <span className="comments-rate">
                    4.8
                  </span>
                  <span className="comments-stars">
                    <span className="icon-android-star" />
                    <span className="icon-android-star" />
                    <span className="icon-android-star" />
                    <span className="icon-android-star" />
                    <span className="icon-android-star-half" />
                  </span>
                  <small className="comments-total">
                    <strong>
                      1,287 clientes satisfechos
                    </strong>
                  </small>
                </div>
                <div className="resume-footer">
                  <div className="comments-logo comments-logo--ml">
                    <img src={mlLogo} alt="" className="img-responsive comments-logo-footer" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </div>
      {/* Content End */}
    </div>
  );
}

export default ToSellPage;
