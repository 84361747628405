export default {
  brands: {
    isFetching: false,
    items: [],
    suggestedIsFetching: false,
    suggestedPrice: null,
  },
  quote: {
    isFetching: false,
    value: 0,
  }
};
