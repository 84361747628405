import { applyMiddleware, createStore, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import rootReducer from './reducers';
import apiMiddleware from './middleware/apiMiddleware';

/* eslint-disable no-underscore-dangle */

let composeEnhancers;
if (process.env.NODE_ENV !== 'production' && window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});
} else {
  composeEnhancers = compose;
}

export default function configureStore(browserHistory, initialState) {
  const middlewares = [apiMiddleware.jwt, thunk, routerMiddleware(browserHistory)];

  if (process.env.NODE_ENV !== 'production') {
    middlewares.push(reduxImmutableStateInvariant());
  }

  return createStore(
    rootReducer,
    initialState,
    composeEnhancers(
      applyMiddleware(...middlewares),
    ),
  );
}
