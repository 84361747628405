import enums from '../../constants/enums';
import apiUtils from '../../utils/apiUtils';


function callApi(action, next, dispatch, getState) {
  let { endpoint, types, method, body, params } = action[enums.engine.CALL_API];
  return apiUtils.call(endpoint, types, method, body, params, null);
}

function jwt({ dispatch, getState }) {

  return (next) => (action) => {

    const callAPI = action[enums.engine.CALL_API];

    if (typeof callAPI === 'undefined') {
      return next(action);
    }

    return callApi(action, next, dispatch, getState);
  };
}

export default { jwt };
