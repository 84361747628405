import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from 'formik';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import uuidv4 from 'uuid/v4';
import HtmlMetaData from "../../components/htmlMetaData";
import Resume from '../../components/resume';
import { validateText, validateEmail } from '../../utils/validate';
import quoteActions from '../../store/actions/quoteActions';
import step2Buy from '../../img/step2-buy.svg';

import './SellUserInfoPage.scss';

function SellUserInfoPage(props) {

  const { match: { params }, actions: { getOffer }, history: { push } } = props;
  const [ resume, setResume ] = useState({});

  const submitInfo = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      const request = {
        seller: {
          name: values.name,
          email: values.email,
          phone: values.telephone,
        },
        condition: resume.condition,
        model: {
          id: resume.model.id,
        },
        suggestedBuyingPrice: resume.price,
        capacity: resume.capacity.value,
      };
      await getOffer(request);
      const uuid = uuidv4();
      const parsedValues = JSON.stringify(request);
      localStorage.setItem(uuid, parsedValues);
      push(`/vender/confirmacion/${uuid}`)
    } catch(error) {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    const { quoteId } = params;
    const data = localStorage.getItem(quoteId);
    if (data) {
      setResume(JSON.parse(data));
    } else {
      push('/');
    }
  }, [params, push]);

  return (
    <div className="sell-user-info">
      <HtmlMetaData title="HayFons | Información de cliente" />
      <div className="container aside-main">
        <h1 className="line-title">
          <span className="line-title-hr" />
          <span className="line-title-text">
            ¡Ya casi terminas!, Sólo faltan tus datos
          </span>
        </h1>
        <section className="main">
          <p className="text-promo text--promo-form">
            <strong>
              <span className="icon-shield" />
              Tus datos están protegidos y no los compartimos con terceros
            </strong>
          </p>
          <div className="card card--form">
            <div className="card-body">
              <div className="card-body-column">
                <Formik
                  initialValues={{
                    name: undefined,
                    email: undefined,
                    telephone: undefined,
                  }}
                  onSubmit={submitInfo}
                >
                  {({ errors, touched, isSubmitting }) => (
                    <Form className="fform-contact">
                      <div className={classnames({
                        'form-group': true,
                        error: errors.name && touched.name,
                      })}>
                        <label htmlFor="name">Nombre</label>
                        <Field name="name" className="form-control" validate={validateText} />
                      </div>
                      <div className={classnames({
                        'form-group': true,
                        error: errors.email && touched.email,
                      })}>
                        <label htmlFor="email">Email</label>
                        <Field name="email" type="email" className="form-control" validate={validateEmail} />
                      </div>
                      <div className={classnames({
                        'form-group': true,
                        error: errors.telephone && touched.telephone,
                      })}>
                        <label htmlFor="telephone">Teléfono</label>
                        <Field name="telephone" type="telephone" className="form-control" validate={validateText} />
                      </div>
                      <button
                        type="submit"
                        className="btn-theme btn--save"
                        disabled={isSubmitting}
                      >
                        Enviar
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
              <div className="card-body-column">
                <img
                  src={step2Buy}
                  alt=""
                  className="img-responsive"
                />
              </div>
            </div>
          </div>
        </section>
        <aside className="aside">
          <Resume selection={resume} price={resume.price} />
        </aside>
      </div>
    </div>
  );
}

const mapDispachToProps = (dispatch) => ({
  actions: bindActionCreators(Object.assign({},
    quoteActions),
  dispatch),
});

export default connect(
  null,
  mapDispachToProps,
)(SellUserInfoPage);
