import enums from '../constants/enums';

function getOffer(request) {
  return {
    [enums.engine.CALL_API]: {
      endpoint: 'https://api.hayfons.com/front/offer',
      method: 'POST',
      body: {
        ...request
      }
    },
  };
}

export default {
  getOffer,
};
