import initialState from './iniitalState';
import { types } from '../actions/quoteActions';

const {
  QUOTE_REQUEST,
  QUOTE_FAIL,
  QUOTE_SUCCESS,
} = types;

export default (state = initialState.quote, action = {}) => {
  switch (action.type) {
  case QUOTE_REQUEST:
  return Object.assign({}, state, {
    isFetching: true,
  });
  case QUOTE_SUCCESS:
  return Object.assign({}, state, {
    isFetching: false,
    error: null,
    value: action.value,
  });
  case QUOTE_FAIL:
  return Object.assign({}, state, {
    isFetching: false,
    error: action.error,
  });
  default:
    return state;
  }
};
