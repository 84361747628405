import { createTypes, asyncAction } from 'redux-action-types';
import brandsApi from '../../engine/brandsApi';

export const types = createTypes('app/brandsActions',
  asyncAction('BRANDS'),
  'SUGGESTED_IS_FETCHING',
  'SET_SUGGESTED',
);

const isFetching = () => {
  return {
    type: types.BRANDS_REQUEST,
  };
};

const suggestedIsFetchig = () => {
  return {
    type: types.SUGGESTED_IS_FETCHING,
  };
};

const setError = (error) => {
  return {
    type: types.BRANDS_FAIL,
    error,
  };
};

const setBrands = brands => {
  return {
    type: types.BRANDS_SUCCESS,
    brands,
  };
};

const setSuggestedPrice = price => {
  return {
    type: types.SET_SUGGESTED,
    price,
  };
};

const getBrands = () => (dispatch) => {
  dispatch(isFetching());
  dispatch(brandsApi.getBrands())
    .then((response) => {
      dispatch(setBrands(response.data));
    }).catch((error) => {
      dispatch(setError(error));
    });
};

const getSuggestedPrice = (request) => dispatch => {
  dispatch(suggestedIsFetchig());
  dispatch(brandsApi.getSuggestedPrice(request))
    .then((response) => {
      dispatch(setSuggestedPrice(response.data.suggestedPrice));
    }).catch((error) => {
      dispatch(setError(error));
    });
};

export default {
  getBrands,
  getSuggestedPrice,
};
