import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

const ConditionButton = (props) => {
  const {
    option,
    selection,
    onClick,
    onBlur,
  } = props;

  const isSelected = _.isEqual(option, selection);

  const classes = classnames({
    card: true,
    'card--link': true,
    'card--center': true,
    'card--active': isSelected,
  });

  const getTitle = option => {
    switch(option) {
      case 'GOOD':
        return 'Regular';
      case 'EXCELLENT':
        return 'Excelente';
      case 'REGULAR':
      default:
        return 'Deficiente';
    }
  }

  return (
    <div className="card-selection-item">
      <button
        className={classes}
        onBlur={onBlur}
        onClick={onClick}
        disabled={isSelected}
        type="button"
      >
        <div className="card-header">
          <h3 className={classnames({
            'card-header-title': true,
            'text-red': option === 'REGULAR',
            'text-orange': option === 'GOOD',
            'text-green': option === 'EXCELLENT',
            'text-left': true,
          })}>
            {getTitle(option)}
          </h3>
          <div className="card-body">
            <ul className="list-bullets text-left">
              {option === 'REGULAR' && (
                <React.Fragment>
                  <li className="list-bullets--item">
                    <p>Daño estetico</p>
                  </li>
                  <li className="list-bullets--item">
                    <p>Sin caja</p>
                  </li>
                  <li className="list-bullets--item">
                    <p>Sin accesorios</p>
                  </li>
                  <li className="list-bullets--item">
                    <p>Sin reporte</p>
                  </li>
                </React.Fragment>)}
              {option === 'GOOD' && (
                <React.Fragment>
                  <li className="list-bullets--item">
                    <p>Buena estetica</p>
                  </li>
                  <li className="list-bullets--item">
                    <p>Sin caja</p>
                  </li>
                  <li className="list-bullets--item">
                    <p>Con accesorios</p>
                  </li>
                  <li className="list-bullets--item">
                    <p>Sin reporte</p>
                  </li>
                </React.Fragment>)}
              {option === 'EXCELLENT' && (
                <React.Fragment>
                  <li className="list-bullets--item">
                    <p>Pantalla intacta</p>
                  </li>
                  <li className="list-bullets--item">
                    <p>Botones funcionando</p>
                  </li>
                  <li className="list-bullets--item">
                    <p>Con caja</p>
                  </li>
                  <li className="list-bullets--item">
                    <p>Con accesorios</p>
                  </li>
                  <li className="list-bullets--item">
                    <p>Sin reporte</p>
                  </li>
                  <li className="list-bullets--item">
                    <p>Con factura</p>
                  </li>
                </React.Fragment>)}
            </ul>
          </div>
        </div>
      </button>
    </div>
  );
}


export default ConditionButton;
