import _ from 'lodash';
import { queryToObject } from './queryStringUtils';

export function getOptions(brands, brand, model) {
  let modelsOptions = [], capacityOptions = [];
  const brandsOptions = _.map(brands, b => ({ id: b.id, name: b.name }));
  const brandSelection = _.find(brands, b => brand && b.id === brand.id);

  if (brandSelection) {
    const models = brandSelection.models;
    modelsOptions = _.map(models, m => ({ id: m.id, name: m.name, images: m.images }));

    const selectedModel = _.find(models, m => model && m.id === model.id);

    if (selectedModel) {
      const capacities = _.sortBy(selectedModel.capacities, c => c);
      capacityOptions = _.map(capacities, c => ({
        value: c,
        display: `${c} G`,
      }));
    }
  }

  return {
    brands: brandsOptions,
    models: _.orderBy(modelsOptions, ['id'], ['asc']),
    capacity: capacityOptions
  }
}

export function getSelection(brands, selection) {
  const values = {
    brand: null,
    model: null,
    condition: null,
    capacity: null,
  };

  const brandSelection = _.find(brands, b => selection.brand && b.name === selection.brand);

  if (!brandSelection) {
    return values;
  }

  values.brand = { name: brandSelection.name, id: brandSelection.id };

  const selectedModel = _.find(brandSelection.models, m => selection.model && m.name === selection.model);

  if (!selectedModel) {
    return values;
  }

  values.model = { name: selectedModel.name, id: selectedModel.id, images: selectedModel.images };

  const capacity = _.find(selectedModel.capacities, c => selection.capacity && c === parseInt(selection.capacity, 10));

  if (!capacity) {
    return values;
  }

  values.capacity = {
    value: capacity,
    display: `${capacity} G`,
  };

  if (!selection.condition) {
    return values;
  }

  values.condition = selection.condition;

  return values;
}

export const setItems = (setFieldValue, setFieldTouched) => (brands, search) => {
  const selection = queryToObject(search);
  console.log(selection);
  const brandsSelection = getSelection(brands, selection);
  _.forEach(Object.entries(brandsSelection), ([key, value]) => {
    setFieldValue(key, value);
    setFieldTouched(key, true);
  });
}

export default {
  getOptions,
  getSelection,
  setItems,
};
