import { combineReducers } from 'redux';
import brands from './brandsReducer';
import quote from './quoteReducer';

const rootReducer = combineReducers({
  brands,
  quote,
});

export default rootReducer;
