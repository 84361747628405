import React from 'react';
import HtmlMetaData from '../../components/htmlMetaData';
import Question from './components/question/Question';

function FaqPage() {
  const questions = [
    {
      question: '¿Qué es Hayfons?',
      answer: (
        <p>
          Somos una empresa que compra y vende celulares usados garantizando el funcionamiento del mismo y generando confiabilidad en nuestros clientes. Hayfons se creo en base a la necesidad de los usuarios a adquirir celulares de ultima generación a bajo costo. Hayfons esta preocupada por el medio ambiente por lo cual generamos el reciclaje de los equipos celulares.
        </p>
      ),
    },
    {
      question: '¿También compran celulares?',
      answer: (
        <p>
          Sí, nuestra empresa se encarga de comprar el celular que ya no uses y facilitarte el desgastante proceso de venta del mismo. Verificamos funcionamiento y detalles estéticos del equipo a comprar y hacemos una oferta lo mas justa posible.
        </p>
      ),
    },
    {
      question: '¿Qué beneficio tengo de comprar con Hayfons?',
      answer: (
        <p>
          Los celulares que tenemos en venta son verificados por nuestro equipo de técnicos, los cuales realizan varias pruebas para verificar la funcionalidad de los mismos. Al comprar con nosotros garantizamos tu compra por un lapso de 90 días naturales.
        </p>
      ),
    },
    {
      question: '¿Qué beneficio tengo de comprar con Hayfons?',
      answer: (
        <p>
          Los celulares que tenemos en venta son verificados por nuestro equipo de técnicos, los cuales realizan varias pruebas para verificar la funcionalidad de los mismos. Al comprar con nosotros garantizamos tú compra por un lapso de 90 días naturales.
        </p>
      ),
    },
    {
      question: '¿Manejan todas las marcas de equipos?',
      answer: (
        <p>
          No, nos especializamos en comprar y vender celulares de las marcas Apple y Samsung.
        </p>
      ),
    },
    {
      question: '¿Comó es el proceso de venta de mi celular?',
      answer: (
        <p>
          Muy sencillo, en nuestra pagina te va indicando paso a paso a seguir para poder contactarnos y darte un estimado del precio de compra de tu celular. Si estas de acuerdo entonces nos ponemos en contacto contigo para cerrar la compra.
        </p>
      ),
    },
    {
      question: '¿Como es el proceso de compra de un celular?',
      answer: (
        <p>
          Tenemos un catalogo de equipos con fotos reales del mismo para verificar la estética de cada uno. Aceptamos todas las tarjetas de crédito. También realizamos la venta personalmente en la Cd de Cancún en alguna plaza comercial para seguridad de ambos.
        </p>
      ),
    },
  ];

  const renderQuestions = (question , i) => (
    <Question key={i} {...question} />
  );

  return (
    <div className="faq-page">
      <HtmlMetaData title="HayFons | Preguntas frecuentes" />
      <div className="container-sm">
        <h1 className="line-title line-title--center">
          <span className="line-title-hr" />
          <span className="line-title-text">
            Preguntas frecuentes
          </span>
        </h1>
        <section className="help">
          <div className="accordion">
            {questions.map(renderQuestions)}
          </div>
        </section>
      </div>
    </div>
  );
}

export default FaqPage;
