import React from 'react';
import HtmlMetaData from '../../components/htmlMetaData';
import step1 from '../../img/step1-sell.svg';
import step2 from '../../img/step2-buy.svg';
import step3 from '../../img/step3-sell.svg';
import step1Buy from '../../img/step1-buy.svg';
import step2Buy from '../../img/step2-buy.svg';
import step3Buy from '../../img/step3-buy.svg';

import './HowItWorksPage.scss';

function HowItWorksPage() {
  return (
    <div className="how-it-works-page">
      <HtmlMetaData title="HayFons | ¿Cómo funciona HayFons?" />
      <div className="container-sm">
        <h1 className="line-title line-title--center">
          <span className="line-title-hr" />
          <span className="line-title-text">
            Preguntas frecuentes
          </span>
        </h1>
        <h3>
          Somos la manera más fácil de comprar y vender celulares usados.
          {' '}
          No piertas tiempo en grupos de venta, aquí te damos el valor adecuado para tu equipo.
          {' '}
          Te explicamos cómo lo hacemos:
        </h3>
        <section className="steps">
          <h3 className="line-title line-title-action">
            <span className="line-title-hr"></span>
            <span className="line-title-text">
              <span className="enfatize-text">COMPRA</span> un teléfono usado
            </span>
            <a href="/">Comprar teléfono <span className="icon-android-arrow-forward"></span></a>
          </h3>
          <div className="grid grid-3-col">
            <div className="grid-item">
              <div className="card card--step">
                <div className="card-header">
                  <h3 className="card-header-title">1.- Encuentra opciones en nuestra lista</h3>
                </div>
                <div className="card-body">
                  <div className="card-body-column">
                    <img src={step1} alt="" className="img-responsive" />
                  </div>
                </div>
              </div>
            </div>
            <div className="grid-item">
              <div className="card card--step">
                <div className="card-header">
                  <h3 className="card-header-title">2.- Déjanos tus datos de contacto</h3>
                </div>
                <div className="card-body">
                  <div className="card-body-column">
                    <img src={step2} alt="" className="img-responsive" />
                  </div>
                </div>
              </div>
            </div>
            <div className="grid-item">
              <div className="card card--step">
                <div className="card-header">
                  <h3 className="card-header-title">3.- ¡Recoge tu nuevo teléfono!</h3>
                </div>
                <div className="card-body">
                  <div className="card-body-column">
                    <img src={step3} alt="" className="img-responsive" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="steps">
          <h3 className="line-title line-title-action">
            <span className="line-title-hr"></span>
            <span className="line-title-text"><span className="enfatize-text">VENDE</span> tu teléfono usado</span>
            <a href="/vender/celular">Vender teléfono <span className="icon-android-arrow-forward"></span></a>
          </h3>
          <div className="grid grid-3-col">
            <div className="grid-item">
              <div className="card card--step">
                <div className="card-header">
                  <h3 className="card-header-title">1.- Dinos las características de tu teléfono</h3>
                </div>
                <div className="card-body">
                  <div className="card-body-column">
                    <img src={step1Buy} alt="" className="img-responsive" />
                  </div>
                </div>
              </div>
            </div>
            <div className="grid-item">
              <div className="card card--step">
                <div className="card-header">
                  <h3 className="card-header-title">2.- Déjanos tus datos de contacto</h3>
                </div>
                <div className="card-body">
                  <div className="card-body-column">
                    <img src={step2Buy} alt="" className="img-responsive" />
                  </div>
                </div>
              </div>
            </div>
            <div className="grid-item">
              <div className="card card--step">
                <div className="card-header">
                  <h3 className="card-header-title">3.- ¡Recibe tu pago!</h3>
                </div>
                <div className="card-body">
                  <div className="card-body-column">
                    <img src={step3Buy} alt="" className="img-responsive" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default HowItWorksPage;
