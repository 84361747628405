import React, { useEffect, useRef, useState } from 'react';
import { shape, string } from 'prop-types';
import { withRouter } from "react-router";
import classnames from 'classnames';
import { Link } from "react-router-dom";
import logo from '../../img/logo.svg';
/* eslint-disable jsx-a11y/anchor-is-valid */

import './Header.scss';

function Header(props) {
  const { location: { pathname } } = props;
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    if (window.innerWidth !== windowWidth.current) {
      windowWidth.current = window.innerWidth;
    }
  });

  const windowWidth = useRef(window.innerWidth);

  const menuMobileClasses = classnames({
    'header-menu-mobile': true,
    'heder-show': windowWidth.current <= 768 && showMenu,
  });

  const onToggleMenu = e => {
    e.preventDefault();
    setShowMenu(showMenu ? false : true);
  };

  return (
    <header className="header">
      <div className="container">
        <div className="header-section">
          <nav className="header-brand">
            <div className="header-brand-left">
              <a href="https://www.hayfons.com/" className="header-brand-logo">
                <img src={logo} alt="Hayfons" />
              </a>
            </div>
            <div className="header-brand-right">
              <div className="header-navigation-mobile">
                <ul className="header-navigation-mobile-sections">
                  <li>
                    <a href="tel:9983901688">
                      <span className="icon-android-call" />
                    </a>
                  </li>
                  <li>
                    <a href="#" onClick={onToggleMenu} className="menu-mobile-toggle">
                      {showMenu ? 'CERRAR' : 'MENU'}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
        <div className={menuMobileClasses}>

          <div className="header-section">
            <nav className="header-navigation">
              <ul className="header-navigation-sections">
                {/* <li className="header-navigation-active"> */}
                <li>
                  <a href="https://compra.hayfons.com">
                    Comprar
                  </a>
                </li>
                <li
                  className={classnames({ 'header-navigation-active': pathname === '/' || pathname === '/vender/celular' })}
                >
                  <Link to="/">
                    Vender
                  </Link>
                </li>
                <li
                  className={classnames({ 'header-navigation-active': pathname === '/como-funciona' })}
                >
                  <Link to="/como-funciona">
                    Cómo funciona
                  </Link>
                </li>
                <li
                  className={classnames({ 'header-navigation-active': pathname === '/preguntas-frecuentes' })}
                >
                  <Link to="/preguntas-frecuentes">
                    Preguntas frecuentes
                  </Link>
                </li>
                <li
                  className={classnames({ 'header-navigation-active': pathname === '/contacto' })}
                >
                  <Link to="/contacto">
                    Contacto
                  </Link>
                </li>
              </ul>
            </nav>
          </div>

          <div className="header-section header-section-right">
            <nav className="header-navigation">
              <ul className="header-navigation-sections">
                <li>
                  <a href="https://wa.me/9983901688" target="_blank" rel="noopener noreferrer">
                    <span className="icon-social-whatsapp" />
                    {' '}
                    <span>
                      998 390 1688
                    </span>
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/hayfons" target="_blank" rel="noopener noreferrer">
                    <span className="icon-social-twitter" />
                    {' '}
                    <span className="header-data-icon-text--mobile">
                      Twitter
                    </span>
                  </a>
                </li>
                <li>
                  <a href="https://facebook.com/Hayfons-452999878571248/" target="_blank" rel="noopener noreferrer">
                    <span className="icon-social-facebook" />
                    {' '}
                    <span className="header-data-icon-text--mobile">
                      Facebook
                    </span>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/hayfons/" target="_blank" rel="noopener noreferrer">
                    <span className="icon-social-instagram" />
                    {' '}
                    <span className="header-data-icon-text--mobile">
                      Instagram
                    </span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}

Header.propTypes = {
  location: shape({
    pathname: string,
  }),
};

export default withRouter(Header);
