import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Form } from 'formik';
import uuidv4 from 'uuid/v4';
import brandsActions from '../../store/actions/brandsActions';
import FormComponents from './components/form';

import './SellCellphonePage.scss';

const SellCellphonePage = (props) => {
  const {
    actions: {
      getBrands,
      getSuggestedPrice,
    },
    history: {
      push,
    },
    brands,
  } = props;

  const getAllBrands = useCallback(
    () => getBrands(),
    [getBrands],
  );

  useEffect(() => {
    getAllBrands();
  }, [getAllBrands]);

  const onSubmit = values => {
    const uuid = uuidv4();
    const parsedValues = JSON.stringify({ ...values, price: brands.suggestedPrice });
    localStorage.setItem(uuid, parsedValues);
    push(`/vender/informacion-usario/${uuid}`);
  };

  return (
    <div className="sell-cellphone-page">
      <div className="container aside-main">
        <Formik
          onSubmit={onSubmit}
          initialValues={{
            brand: undefined,
            model: undefined,
            capacity: undefined,
            condition: undefined,
          }}
          render={(props) => (
            <Form className="sell-cellphone-form">
              <FormComponents
                brands={brands}
                getSuggestedPrice={getSuggestedPrice}
                {...props}
              />
            </Form>)}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  brands: state.brands,
});

const mapDispachToProps = (dispatch) => ({
  actions: bindActionCreators(Object.assign({},
    brandsActions),
  dispatch),
});

export default connect(
  mapStateToProps,
  mapDispachToProps,
)(SellCellphonePage);
