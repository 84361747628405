import { stringify, parse } from 'qs';
import _ from 'lodash';

export function queryToObject(query) {
  let newObject = {};
  if (!_.isEmpty(query)) {
    newObject = parse(query.replace('?', ''),
      { arrayFormat: 'indices', allowDots: true, encode: false });
  }
  return newObject;
}

export function objectToQueryString(values) {
  const params = {};

  _.forOwn(values, (value, key) => {
    if (value && key) {
      params[key] = encodeURIComponent(value);
    }
  });

  return stringify(params, { arrayFormat: 'indices', allowDots: true, encode: false });
}

export default {
  queryToObject,
  objectToQueryString,
};
