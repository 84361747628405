import React, { useEffect, useCallback } from "react";
import { Field } from "formik";
import { StickyContainer, Sticky } from "react-sticky";
import { Element, scroller } from "react-scroll";
import { withRouter } from "react-router";
import _ from "lodash";
import { objectToQueryString } from "../../../../utils/queryStringUtils";
import { getOptions, setItems } from "../../../../utils/brandsUtils";
import enums from "../../../../constants/enums";
import Resume from "../../../../components/resume";
import SimpleButton from "../simpleButton";
import CellphoneButton from "../cellphoneButton";
import ConditionButton from "../conditionButton";
import ListIntput from "../../../../components/form/listInput";

const Form = (props) => {
  const {
    brands,
    setFieldValue,
    setFieldTouched,
    values,
    getSuggestedPrice,
    history: {
      push,
      location: { search },
    },
  } = props;

  const { brand, model, capacity, condition } = values;

  const setFields = useCallback(
    (brands, search) =>
      setItems(setFieldValue, setFieldTouched)(brands, search),
    [setFieldValue, setFieldTouched]
  );

  const getSuggested = useCallback((request) => getSuggestedPrice(request), [
    getSuggestedPrice,
  ]);

  useEffect(() => {
    if (model && capacity && condition) {
      getSuggested({ model, capacity, condition });
    }
  }, [model, capacity, condition, getSuggested]);

  useEffect(() => {
    if (brands.items.length > 0) {
      setFields(brands.items, search);
    }
  }, [search, brands.items, setFields]);

  const onBrandClick = (option) => {
    setRoute({ brand: option.name });
  };

  const onModelClick = (option) => {
    setRoute({ brand: brand.name, model: option.name });
    scrollToElement('capacityElement');
  };

  const onCapacityClick = (option) => {
    setRoute({
      brand: brand.name,
      model: model.name,
      capacity: option.value,
    });
  };

  const onConditionClick = (option) => {
    setRoute({
      brand: brand.name,
      model: model.name,
      capacity: capacity.value,
      condition: option,
    });
  };

  const setRoute = (params) => {
    const query = objectToQueryString(params);
    push(`/vender/celular?${query}`);
  };

  const getConditionsOptions = () => {
    return _.map(enums.conditions, (condition) => condition);
  };

  const options = getOptions(brands.items, brand, model);

  const scrollToElement = (element) => {
    scroller.scrollTo(element, {
      smooth: true,
    });
  };

  return (
    <React.Fragment>
      <h1 className="line-title">
        <span className="line-title-hr" />
        <span className="line-title-text">
          Cuéntanos las características del teléfono
        </span>
      </h1>
      <section className="main">
        <div className="card-selection card-selection-4">
          <h3 className="card-selection-title">¿Cuál es la marca?</h3>
          <Field
            name="brand"
            component={ListIntput}
            options={options.brands}
            onClick={onBrandClick}
            RenderComponent={SimpleButton}
          />
        </div>
        {!_.isEmpty(brand) && (
          <div className="card-selection card-selection-4 fade">
            <h3 className="card-selection-title">¿Cuál es el modelo?</h3>
            <Field
              name="model"
              component={ListIntput}
              options={options.models}
              onClick={onModelClick}
              RenderComponent={CellphoneButton}
            />
          </div>
        )}
        <Element name="capacityElement">
          {!_.isEmpty(model) && (
            <div className="card-selection card-selection-4 fade">
              <h3 className="card-selection-title">
                ¿Qué capacidad tiene?
              </h3>
              <Field
                name="capacity"
                component={ListIntput}
                options={options.capacity}
                onClick={onCapacityClick}
                display="display"
                RenderComponent={SimpleButton}
              />
            </div>
          )}
        </Element>
        {!_.isEmpty(capacity) && (
          <div className="card-selection card-selection-3 fade">
            <h3 className="card-selection-title">
              ¿En qué estado físico se encuentra?
            </h3>
            <Field
              name="condition"
              component={ListIntput}
              options={getConditionsOptions()}
              onClick={onConditionClick}
              RenderComponent={ConditionButton}
            />
          </div>
        )}
      </section>
      <StickyContainer className="aside">
        <Sticky topOffset={-20}>
          {({ style, isSticky }) => (
            <div style={{ ...style, marginTop: isSticky ? 20 : 0 }}>
              <Resume
                selection={values}
                isLoading={brands.suggestedIsFetching}
                price={brands.suggestedPrice}
                showButton
              />
            </div>
          )}
        </Sticky>
      </StickyContainer>
    </React.Fragment>
  );
};

export default withRouter(Form);
