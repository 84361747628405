import React from 'react';
import { Helmet }  from 'react-helmet';

const HtmlMetaData = ({ title }) => (
  <Helmet>
    <meta charSet="utf-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <title>{title}</title>
    {/* <link rel="canonical" href="http://mysite.com/example" /> */}
  </Helmet>
);

export default HtmlMetaData;
