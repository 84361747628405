import initialState from './iniitalState';
import { types } from '../actions/brandsActions';

const {
  BRANDS_REQUEST,
  BRANDS_FAIL,
  BRANDS_SUCCESS,
  SUGGESTED_IS_FETCHING,
  SET_SUGGESTED,
} = types;

export default (state = initialState.brands, action = {}) => {
  switch (action.type) {
  case BRANDS_REQUEST:
  return Object.assign({}, state, {
    isFetching: true,
  });
  case BRANDS_SUCCESS:
  return Object.assign({}, state, {
    isFetching: false,
    error: null,
    items: action.brands,
  });
  case BRANDS_FAIL:
  return Object.assign({}, state, {
    isFetching: false,
    error: action.error,
  });
  case SUGGESTED_IS_FETCHING:
  return {
    ...state,
    suggestedIsFetching: true,
    suggestedPrice: null,
  };
  case SET_SUGGESTED:
  return {
    ...state,
    suggestedIsFetching: false,
    suggestedPrice: action.price,
  };
  default:
    return state;
  }
};
