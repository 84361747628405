import axios from 'axios';

// function renewAccessToken() {
//    return axios.request({
//       url: '/login',
//       method: 'POST',
//       data: {
//          username: API_USR,
//          password: API_PASS
//       }
//    });
// }

function call(endpoint, types, method, body, params, access_token) {
  let config = {
    url: endpoint,
    method: method,
    headers: {}
  };

  if (body && body !== null) {
    config.data = body;
  }

  if (params && params !== null) {
    config.params = params;
  }

  if (access_token) {
    config.headers['Authorization'] = `Bearer ${access_token}`;
  }

  return axios.request(config);
}

export default {
  // renewAccessToken,
  call
};
