import React from "react";
import _ from "lodash";
import classnames from "classnames";
import cellphone from "../../../../img/cellphone.svg";

const SimpleButton = (props) => {
  const { option, selection, onClick, onBlur, showImage, display } = props;

  const isSelected = _.isEqual(option, selection);

  const classes = classnames({
    card: true,
    "card--link": true,
    "card--center": true,
    "card--active": isSelected,
  });

  const hasPicture = showImage && option.images && option.images.length > 0;
  const img = hasPicture ? option.images[0].path : cellphone;

  return (
    <div className="card-selection-item">
      <button
        className={classes}
        onBlur={onBlur}
        onClick={onClick}
        disabled={isSelected}
        type="button"
      >
        <div className="card-header">
          <h3 className="card-header-title">
            {/* <span className="icon-social-apple" /> */}
            {display ? option[display] : option.name}
          </h3>
        </div>
        {showImage && (
          <div className="card-body">
            <img
              src={img}
              alt=""
              className={classnames({
                "img-responsive": true,
                "default-icon": !hasPicture,
              })}
            />
          </div>
        )}
      </button>
    </div>
  );
};

export default SimpleButton;
